import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "2017 - 2021",
      title: "Mechatronics Engineering",
      place: "Thanlyin Technological University",
      desc: "I attended for three years, where I studied Computer Science and gained valuable knowledge and skills in programming",
    },
    {
      yearRange: "2020 - 2021",
      title: "Programmingwithmosh Courses",
      place: "Online",
      desc: "I completed the React and Node courses by Mosh, where I gained expertise in two essential technologies for modern software development.",
    },
    {
      yearRange: "2020 - 2021",
      title: "Freecodecamp Courses",
      place: "Online",
      desc: "I completed courses by FreeCodeCamp, where I gained a strong foundation in HTML, CSS, and Git, essential skills for building and managing websites",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2020 - current",
      title: "Full Stack Developer",
      place: "Freelance",
      desc: "I am committed to delivering high-quality work and providing value to my clients' projects.",
    },
    {
      yearRange: "2021 - current",
      title: "Full Stack Developer",
      place: "Studio AMK Co.,Ltd",
      desc: " I am dedicated to achieving success and delivering exceptional work in my current role.",
    },
    {
      yearRange: "2023 - current",
      title: "Frontend Blockchain Developer",
      place: "Launch Legends Co.,Ltd",
      desc: "I work part-time as a Blockchain and Next.js developer, focusing on building decentralized applications",
    },
  ];

  const skills = [
    {
      name: "React-js/Next-js/Capacitor-js",
      percent: 100,
    },
    {
      name: "Node-js/Microservices/Moleculer/Ejs",
      percent: 100,
    },
    {
      name: "Mysql/Redis/MongoDb",
      percent: 100,
    },
    {
      name: "Git",
      percent: 100,
    },
    {
      name: "Cocos Creator",
      percent: 100,
    },
    {
      name: "React-Native/Android Studio",
      percent: 90,
    },
    {
      name: "Digital Ocean",
      percent: 100,
    },
    {
      name: " Html,Css,Javascript,typescript,Java",
      percent: 100,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A summary of My Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
